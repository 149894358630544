import { default as _91token_93U3K0OmiXXXMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/change-password/[token].vue?macro=true";
import { default as _91token_93_46clientOmNGpkVSu5Meta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/confirm/[token].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/codebuild/output/src2414147687/src/spilky-client/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91token_932g5ibrItHpMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/create-password/[token].vue?macro=true";
import { default as _91token_93_46clientkQAr5V76hkMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/invite/[token].client.vue?macro=true";
import { default as google_46clientS1nU6fiyJGMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/oauth/google.client.vue?macro=true";
import { default as _91token_93OhpyQZ7RGtMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/reset-password/[token].vue?macro=true";
import { default as request5QviUmZF6PMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/reset-password/request.vue?macro=true";
import { default as sign_45inYigVzcpVMNMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-in.vue?macro=true";
import { default as indexqTlVtZrZ0gMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/index.vue?macro=true";
import { default as _1vgqbwi7YKSMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/step/1.vue?macro=true";
import { default as _2V58SOb1OyjMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/step/2.vue?macro=true";
import { default as _353owvMCzYJMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/step/3.vue?macro=true";
import { default as _4BwPkQAPg76Meta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/step/4.vue?macro=true";
import { default as stepx1WXmIVySMMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/step.vue?macro=true";
import { default as widget_45simulator_45_91token_93_46clientZpNVA8JhrXMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/external/widget-simulator-[token].client.vue?macro=true";
import { default as indexmKQpeGalxfMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/index.vue?macro=true";
import { default as accountnOGRaTkZpKMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/account.vue?macro=true";
import { default as _91id_93rvKtCELHqhMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/billing/invoice/[id].vue?macro=true";
import { default as billingB4gsKg6RKZMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/billing.vue?macro=true";
import { default as _91cid_93wqxLuDu7XtMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/chat/[kind]/[cid].vue?macro=true";
import { default as _91kind_939UljMGT3U0Meta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/chat/[kind].vue?macro=true";
import { default as chatxR2EJJSvGBMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/chat.vue?macro=true";
import { default as inactive_45operatordHEaIa1ltiMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/inactive-operator.vue?macro=true";
import { default as knowledge_45baseZu7m2q4dHFMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/knowledge-base.vue?macro=true";
import { default as accountUJmcUsVfbRMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/account.vue?macro=true";
import { default as basic_45settingseMsTfuBjg8Meta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/basic-settings.vue?macro=true";
import { default as operatorsEgUaeU4GJGMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/operators.vue?macro=true";
import { default as basic_45settingsPfr9Ujz79qMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/basic-settings.vue?macro=true";
import { default as operatorseY56lIgqAuMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/operators.vue?macro=true";
import { default as basic_45settingsYkEjdd94IrMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/basic-settings.vue?macro=true";
import { default as code_45scriptGD3hibgmCqMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/code-script.vue?macro=true";
import { default as contactsbuWiPIkJQQMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/contacts.vue?macro=true";
import { default as operatorsLyKeTwbzTZMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/operators.vue?macro=true";
import { default as personalizationNxVnzpfQKEMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/personalization.vue?macro=true";
import { default as configureLRJMeirDPPMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure.vue?macro=true";
import { default as telegramwo1r4EPzf5Meta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/connect/telegram.vue?macro=true";
import { default as viberekP6ysrC70Meta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/connect/viber.vue?macro=true";
import { default as channelvgHhWnLH1OMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel.vue?macro=true";
import { default as settings3gNamdCHEsMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/settings.vue?macro=true";
import { default as work_45analyticssun02TSPCrMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-analytics.vue?macro=true";
import { default as work_45schedulea21qJyzbsiMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-schedule.vue?macro=true";
import { default as editeCft0Ol5fXMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit.vue?macro=true";
import { default as createIyt6yz6sX9Meta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator/create.vue?macro=true";
import { default as operatorwi9QAs6iONMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator.vue?macro=true";
import { default as logory9o5tcOxzMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/logo.vue?macro=true";
import { default as settingsxX4DATrHUzMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/settings.vue?macro=true";
import { default as editfbsXUehKXZMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit.vue?macro=true";
import { default as createl7Gxgo3DMFMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/project/create.vue?macro=true";
import { default as projectyNkdIWcLwsMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/project.vue?macro=true";
import { default as basic_45settingsTg3Zhrv1taMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/basic-settings.vue?macro=true";
import { default as personalizationalmGvqDc0JMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/personalization.vue?macro=true";
import { default as seo_45and_45analyticsrqlGoH5CQSMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/seo-and-analytics.vue?macro=true";
import { default as public_45knowledge_45base6WBeHtu4v1Meta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base.vue?macro=true";
import { default as edit1igFT9NoCAMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/quick-answer/[id]/edit.vue?macro=true";
import { default as createG4C6Rwv0JOMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/quick-answer/create.vue?macro=true";
import { default as quick_45answerKbAUthfhyBMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/quick-answer.vue?macro=true";
import { default as settingl3cbuptKkZMeta } from "/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting.vue?macro=true";
export default [
  {
    name: "language-auth-change-password-token",
    path: "/:language?/auth/change-password/:token()",
    meta: _91token_93U3K0OmiXXXMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/change-password/[token].vue")
  },
  {
    name: "language-auth-confirm-token",
    path: "/:language?/auth/confirm/:token()",
    meta: _91token_93_46clientOmNGpkVSu5Meta || {},
    component: () => createClientPage(() => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/confirm/[token].client.vue"))
  },
  {
    name: "language-auth-create-password-token",
    path: "/:language?/auth/create-password/:token()",
    meta: _91token_932g5ibrItHpMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/create-password/[token].vue")
  },
  {
    name: "language-auth-invite-token",
    path: "/:language?/auth/invite/:token()",
    meta: _91token_93_46clientkQAr5V76hkMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/invite/[token].client.vue"))
  },
  {
    name: "language-auth-oauth-google",
    path: "/:language?/auth/oauth/google",
    meta: google_46clientS1nU6fiyJGMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/oauth/google.client.vue"))
  },
  {
    name: "language-auth-reset-password-token",
    path: "/:language?/auth/reset-password/:token()",
    meta: _91token_93OhpyQZ7RGtMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/reset-password/[token].vue")
  },
  {
    name: "language-auth-reset-password-request",
    path: "/:language?/auth/reset-password/request",
    meta: request5QviUmZF6PMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/reset-password/request.vue")
  },
  {
    name: "language-auth-sign-in",
    path: "/:language?/auth/sign-in",
    meta: sign_45inYigVzcpVMNMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-in.vue")
  },
  {
    name: "language-auth-sign-up",
    path: "/:language?/auth/sign-up",
    meta: indexqTlVtZrZ0gMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/index.vue")
  },
  {
    name: "language-auth-sign-up-step",
    path: "/:language?/auth/sign-up/step",
    meta: stepx1WXmIVySMMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/step.vue"),
    children: [
  {
    name: "language-auth-sign-up-step-1",
    path: "1",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/step/1.vue")
  },
  {
    name: "language-auth-sign-up-step-2",
    path: "2",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/step/2.vue")
  },
  {
    name: "language-auth-sign-up-step-3",
    path: "3",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/step/3.vue")
  },
  {
    name: "language-auth-sign-up-step-4",
    path: "4",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/[[language]]/auth/sign-up/step/4.vue")
  }
]
  },
  {
    name: "external-widget-simulator-token",
    path: "/external/widget-simulator-:token()",
    meta: widget_45simulator_45_91token_93_46clientZpNVA8JhrXMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src2414147687/src/spilky-client/pages/external/widget-simulator-[token].client.vue"))
  },
  {
    name: "index",
    path: "/",
    meta: indexmKQpeGalxfMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/index.vue")
  },
  {
    name: "p-pid-account",
    path: "/p/:pid()/account",
    meta: accountnOGRaTkZpKMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/account.vue")
  },
  {
    name: "p-pid-billing",
    path: "/p/:pid()/billing",
    meta: billingB4gsKg6RKZMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/billing.vue"),
    children: [
  {
    name: "p-pid-billing-invoice-id",
    path: "invoice/:id()",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/billing/invoice/[id].vue")
  }
]
  },
  {
    name: "p-pid-chat",
    path: "/p/:pid()/chat",
    meta: chatxR2EJJSvGBMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/chat.vue"),
    children: [
  {
    name: "p-pid-chat-kind",
    path: ":kind()",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/chat/[kind].vue"),
    children: [
  {
    name: "p-pid-chat-kind-cid",
    path: ":cid()",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/chat/[kind]/[cid].vue")
  }
]
  }
]
  },
  {
    name: "p-pid-inactive-operator",
    path: "/p/:pid()/inactive-operator",
    meta: inactive_45operatordHEaIa1ltiMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/inactive-operator.vue")
  },
  {
    name: "p-pid-knowledge-base",
    path: "/p/:pid()/knowledge-base",
    meta: knowledge_45baseZu7m2q4dHFMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/knowledge-base.vue")
  },
  {
    name: "p-pid-setting",
    path: "/p/:pid()/setting",
    meta: settingl3cbuptKkZMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting.vue"),
    children: [
  {
    name: "p-pid-setting-account",
    path: "account",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/account.vue")
  },
  {
    name: "p-pid-setting-channel",
    path: "channel",
    meta: channelvgHhWnLH1OMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel.vue"),
    children: [
  {
    name: "p-pid-setting-channel-id-configure",
    path: ":id()/configure",
    meta: configureLRJMeirDPPMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure.vue"),
    children: [
  {
    name: "p-pid-setting-channel-id-configure-telegram-basic-settings",
    path: "telegram/basic-settings",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-telegram-operators",
    path: "telegram/operators",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-viber-basic-settings",
    path: "viber/basic-settings",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-viber-operators",
    path: "viber/operators",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-basic-settings",
    path: "widget/basic-settings",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-code-script",
    path: "widget/code-script",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/code-script.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-contacts",
    path: "widget/contacts",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/contacts.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-operators",
    path: "widget/operators",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-personalization",
    path: "widget/personalization",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/personalization.vue")
  }
]
  },
  {
    name: "p-pid-setting-channel-connect-telegram",
    path: "connect/telegram",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/connect/telegram.vue")
  },
  {
    name: "p-pid-setting-channel-connect-viber",
    path: "connect/viber",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/channel/connect/viber.vue")
  }
]
  },
  {
    name: "p-pid-setting-operator",
    path: "operator",
    meta: operatorwi9QAs6iONMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator.vue"),
    children: [
  {
    name: "p-pid-setting-operator-id-edit",
    path: ":id()/edit",
    meta: editeCft0Ol5fXMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit.vue"),
    children: [
  {
    name: "p-pid-setting-operator-id-edit-settings",
    path: "settings",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/settings.vue")
  },
  {
    name: "p-pid-setting-operator-id-edit-work-analytics",
    path: "work-analytics",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-analytics.vue")
  },
  {
    name: "p-pid-setting-operator-id-edit-work-schedule",
    path: "work-schedule",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-schedule.vue")
  }
]
  },
  {
    name: "p-pid-setting-operator-create",
    path: "create",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/operator/create.vue")
  }
]
  },
  {
    name: "p-pid-setting-project",
    path: "project",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/project.vue"),
    children: [
  {
    name: "p-pid-setting-project-id-edit",
    path: ":id()/edit",
    meta: editfbsXUehKXZMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit.vue"),
    children: [
  {
    name: "p-pid-setting-project-id-edit-logo",
    path: "logo",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/logo.vue")
  },
  {
    name: "p-pid-setting-project-id-edit-settings",
    path: "settings",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/settings.vue")
  }
]
  },
  {
    name: "p-pid-setting-project-create",
    path: "create",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/project/create.vue")
  }
]
  },
  {
    name: "p-pid-setting-public-knowledge-base",
    path: "public-knowledge-base",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base.vue"),
    children: [
  {
    name: "p-pid-setting-public-knowledge-base-basic-settings",
    path: "basic-settings",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/basic-settings.vue")
  },
  {
    name: "p-pid-setting-public-knowledge-base-personalization",
    path: "personalization",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/personalization.vue")
  },
  {
    name: "p-pid-setting-public-knowledge-base-seo-and-analytics",
    path: "seo-and-analytics",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/seo-and-analytics.vue")
  }
]
  },
  {
    name: "p-pid-setting-quick-answer",
    path: "quick-answer",
    meta: quick_45answerKbAUthfhyBMeta || {},
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/quick-answer.vue"),
    children: [
  {
    name: "p-pid-setting-quick-answer-id-edit",
    path: ":id()/edit",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/quick-answer/[id]/edit.vue")
  },
  {
    name: "p-pid-setting-quick-answer-create",
    path: "create",
    component: () => import("/codebuild/output/src2414147687/src/spilky-client/pages/p/[pid]/setting/quick-answer/create.vue")
  }
]
  }
]
  }
]